(function (window, document, $) {
  $(document).ready(function () {
    $sliderCardPicture = $('.mcl-slider-card-picture');
    if ($sliderCardPicture.length > 0) {
      const carousels = document.querySelectorAll('.mcl-carousel-card-picture-slider');
      const setImage = (event) => {
        setTimeout(function () {
          $img = $sliderCardPicture.find('.mcl-desktop-only img');
          $img.attr('src', $(event.target).closest('.owl-carousel').find('.owl-item.active img').attr('src'));
        }, 1);
      };
      Array.prototype.forEach.call(carousels, (carousel) => {
        const owlC = carousel.querySelector('.owl-carousel');
        const prevButton = carousel.querySelector('.owl-prev');
        const nextButton = carousel.querySelector('.owl-next');
        prevButton.addEventListener('click', setImage);
        nextButton.addEventListener('click', setImage);
        $(owlC).on('changed.owl.carousel', setImage);
      });
    }
  });
})(window, document, jQuery);
