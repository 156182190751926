(function (window, document, $) {
  $(document).ready(function () {
    $accordion = $('.mcl-accordion');
    if ($accordion.length > 0) {
      $accordion.find("div:first").css("display", "block");
      $accordion.find("h3:first svg").addClass('rotate');
      $accordion.find("h3").click(function () {
        $(this).next().slideToggle(300);
        $accordion.find("div").not($(this).next()).slideUp(300);
        //$accordion.find("h3 svg").removeClass('rotate');
        $(this).find("svg").toggleClass('rotate');
        $accordion.find('h3 svg').not($(this).find('svg')).removeClass('rotate');
      });
    }
  })
})(window, document, jQuery);
