(function ($, window) {
  $tabAnchor = $('.mcl-tab-anchor');
  if ($tabAnchor.length > 0) {
    $tabAnchor.each(function (i, el) {

      $(el).find('#tabs li a').on('click touch', function (e) {
        e.preventDefault();
        var t = $(this).attr('id');
        if ($(this).closest('li').hasClass('inactive')) {
          $(el).find('#tabs li').addClass('inactive');
          $(this).closest('li').removeClass('inactive');
          let $id = $(this).attr('href');
          if ($($id).length > 0) {
            $('html, body').animate({
              scrollTop: $($id).offset().top - 50
            }, 500);
          }
        }
      });
    });
  }
})(jQuery, window);
