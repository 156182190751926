(function (window, document, $) {
    $(document).ready(function () {
        $accordionMenuRightDrawer = $('.mcl-accordion-menu-right-drawer');
        $allPanel = $accordionMenuRightDrawer.find('.mcl-title');
        if ($accordionMenuRightDrawer.length > 0) {
            $accordionMenuRightDrawer.find('.mcl-title').on('click touch', function (e) {
                e.preventDefault();
                if (!$(this).hasClass('active')) {
                    $allPanel.removeClass('active');
                    $allPanel.siblings().slideUp();
                    $allPanel.find('.mcl-icon').removeClass('animate');
                    $(this).addClass('active');
                    $(this).siblings().slideDown();
                } else {
                    $(this).removeClass('active');
                    $(this).siblings().slideUp();
                }
                $(this).parent().find('.mcl-icon').toggleClass('animate');

            });
        }
    })
})(window, document, jQuery);
