(function ($, window) {
  const $resultSearchServices = $('.mcl-services-template');
  if ($resultSearchServices.length > 0) {
    $servicesFilters = $resultSearchServices.find('.mcl-services-filters');
    $cptType = $resultSearchServices.attr('data-type');
    $form = $resultSearchServices.find('form');
    $resultSearchServices.each(function (i, el) {

      $servicesFilters.on('MDCSelect:change', '.mdc-select', function (e) {
        e.preventDefault();
        $form = $servicesFilters.find('form');

        const obiettivo = $form.find('input[name=obiettivo]').val();
        const richiedente = $form.find('input[name=richiedente]').val();

        var url = ajax_url,
          data = {
            nextPage: 1,
            action: 'paginationServices',
            cptType: $cptType,
            obiettivo: obiettivo,
            richiedente: richiedente,
            postsPerPage: $resultSearchServices.attr('data-post-per-page')
          };

        $.post(url, data, function (resp) {
          var respObj = JSON.parse(resp);
          $resultWrapper = $resultSearchServices.find('.mcl-resuls-wrapper');
          $resultWrapper.empty();
          $resultWrapper.html(respObj.markup);
          $resultSearchServices.find('.mcl-button.show-more').attr('data', 2);
          if (respObj.maxNumPages > 0) {
            if (respObj.hasNext) {
              $resultSearchServices.find('.mcl-button.show-more').show();
            } else {
              $resultSearchServices.find('.mcl-button.show-more').hide();
            }
          } else {
          }

          $('html, body').animate({
            scrollTop: $servicesFilters.offset().top - 100
          }, 1000);

          return false;
        });
      });

      // Bottone mostra di più visibile solo su mobile
      $resultSearchServices.on('click', '.mcl-button.show-more', function (e) {
        e.preventDefault();
        $form = $servicesFilters.find('form');

        const obiettivo = $form.find('input[name=obiettivo]').val();
        const richiedente = $form.find('input[name=richiedente]').val();

        var url = ajax_url,
          data = {
            nextPage: $(this).attr('data'),
            action: 'paginationServices',
            cptType: $cptType,
            obiettivo: obiettivo,
            richiedente: richiedente,
            postsPerPage: $resultSearchServices.attr('data-post-per-page')
          };

        $.post(url, data, function (resp) {
          var respObj = JSON.parse(resp);
          $resultWrapper = $resultSearchServices.find('.mcl-resuls-wrapper');
          $resultWrapper.append(respObj.markup);
          $showMoreButton = $resultSearchServices.find('.mcl-button.show-more');
          $showMoreButton.attr('data', parseInt($showMoreButton.attr('data')) + 1);
          if (!respObj.hasNext)
            $showMoreButton.hide();
          return false;
        });
      });

    });
  }
})(jQuery, window);
