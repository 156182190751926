(function (window, document, $) {

  $(document).ready(function () {
    $inclinedPhoto = $('.mcl-inclined-photo');

    if ($inclinedPhoto.length > 0) {

      $inclinedPhoto.on('mouseenter', '.mcl-image', function () {
        $(this).css('transform', 'rotate(-11deg)');
        $inclinedPhoto.find('.mcl-image-wrapper').addClass('animated');
      }).on('mouseleave', '.mcl-image', function () {
        $(this).css('transform', 'rotate(-9deg)');
        $inclinedPhoto.find('.mcl-image-wrapper').removeClass('animated');
      });


    }
  })
})(window, document, jQuery);
