/* NPM COMPONENTS */
import 'jquery-modal';
import 'material-components-web';
import 'jquery-validation';
import 'owl.carousel';

// general
import './_general';

// Components
import '../../components/_components';

// layouts
import '../../layouts/news-template/news-template';
import '../../layouts/services-template/services-template';
import '../../layouts/projects-template/projects-template';
