(function ($, window) {
  $resultSearchProjects = $('.mcl-projects-template');
  if ($resultSearchProjects.length > 0) {
    $projectsFilters = $resultSearchProjects.find('.mcl-projects-filters');
    $cptType = $resultSearchProjects.attr('data-type');
    $form = $resultSearchProjects.find('form');
    $resultSearchProjects.each(function (i, el) {

      $projectsFilters.on('MDCSelect:change', '.mdc-select', function (e) {
        e.preventDefault();
        $form = $projectsFilters.find('form');

        const tipoIntervento = $form.find('input[name=tipo_intervento]').val();
        const richiedente = $form.find('input[name=richiedente]').val();

        var url = ajax_url,
          data = {
            nextPage: 1,
            action: 'paginationProjects',
            cptType: $cptType,
            tipoIntervento: tipoIntervento,
            richiedente: richiedente,
            postsPerPage: $resultSearchProjects.attr('data-post-per-page')
          };

        $.post(url, data, function (resp) {
          var respObj = JSON.parse(resp);
          $resultWrapper = $resultSearchProjects.find('.mcl-resuls-wrapper');
          $resultWrapper.empty();
          $resultWrapper.html(respObj.markup);
          $resultSearchProjects.find('.mcl-button.show-more').attr('data', 2);
          if (respObj.maxNumPages > 0) {
            if (respObj.hasNext) {
              $resultSearchProjects.find('.mcl-button.show-more').show();
            } else {
              $resultSearchProjects.find('.mcl-button.show-more').hide();
            }
          } else {
          }

          $('html, body').animate({
            scrollTop: $projectsFilters.offset().top - 100
          }, 1000);

          return false;
        });
      });

      // Bottone mostra di più visibile solo su mobile
      $resultSearchProjects.on('click', '.mcl-button.show-more', function (e) {
        e.preventDefault();
        $form = $projectsFilters.find('form');

        const tipoIntervento = $form.find('input[name=tipo_intervento]').val();
        const richiedente = $form.find('input[name=richiedente]').val();

        var url = ajax_url,
          data = {
            nextPage: $(this).attr('data'),
            action: 'paginationProjects',
            cptType: $cptType,
            tipoIntervento: tipoIntervento,
            richiedente: richiedente,
            postsPerPage: $resultSearchProjects.attr('data-post-per-page')
          };

        $.post(url, data, function (resp) {
          var respObj = JSON.parse(resp);
          $resultWrapper = $resultSearchProjects.find('.mcl-resuls-wrapper');
          $resultWrapper.append(respObj.markup);
          $showMoreButton = $resultSearchProjects.find('.mcl-button.show-more');
          $showMoreButton.attr('data', parseInt($showMoreButton.attr('data')) + 1);
          if (!respObj.hasNext)
            $showMoreButton.hide();
          return false;
        });
      });

    });
  }
})(jQuery, window);
