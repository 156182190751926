(function ($, window) {
  const convertOptions = (options) => (
    Object.keys(options).reduce((res, key) => {
      switch (key) {
        case 'elements':
          res.responsive[0].items = parseInt(options[key]);
          break;
        case 'elementsSmartphone':
          res.responsive[480].items = parseInt(options[key]);
          break;
        case 'elementsTablet':
          res.responsive[600].items = parseInt(options[key]);
          break;
        case 'elementsBigTablet':
          res.responsive[768].items = parseInt(options[key]);
          break;
        case 'elementsLargeTablet':
          res.responsive[992].items = parseInt(options[key]);
          break;
        case 'elementsDesktop':
          res.responsive[1024].items = parseInt(options[key]);
          break;
        case 'elementsLargeDesktop':
          res.responsive[1200].items = parseInt(options[key]);
          break;
        default:
          res[key] = parseInt(options[key]) || options[key];
          if (typeof res[key] === 'string' && res[key].split(',').length > 1) {
            res[key] = res[key].split(',')
          }
          if (res[key] === 'true') res[key] = true;
          if (res[key] === 'false') res[key] = false;
      }
      return res;
    }, {
      responsive: {
        0: {items: 1},
        480: {items: 1},
        600: {items: 1},
        768: {items: 1},
        992: {items: 1},
        1024: {items: 1},
        1200: {items: 1},
      }
    })
  );

  const carousels = document.querySelectorAll('.mcl-carousel');
  window.carousels = [];
  Array.prototype.forEach.call(carousels, (carousel) => {
    const owlC = carousel.querySelector('.owl-carousel');
    let options = Object.assign({}, owlC.dataset);
    options = convertOptions(options);
    if (options.nav) {
      const navs = carousel.querySelector('.owl-carousel-hide-element');
      options.navText = Array.prototype.map.call(navs.children, (nav) => (nav.outerHTML));
    }
    // options.animateOut = 'fadeOut';
    // options.animateIn = 'fadeIn';
    let owlCarousel = $(owlC).owlCarousel(options);
    owlCarousel.on('resized.owl.carousel', function () {
      owlCarousel.trigger('refresh.owl.carousel');
      window.dispatchEvent(new Event('resize'));
    });
    if (options['loop'] === false) {
      owlCarousel.on('initialized.owl.carousel changed.owl.carousel refreshed.owl.carousel', function (event) {
        if (!event.namespace) return;
        var carousel = event.relatedTarget,
          element = event.target,
          current = carousel.current();
        $('.owl-next', element).toggleClass('hidden', current === carousel.maximum());
        $('.owl-prev', element).toggleClass('hidden', current === carousel.minimum());
      });
      owlCarousel.on('changed.owl.carousel', function (e) {
        owlCarousel.trigger('stop.owl.autoplay');
        //  owlCarousel.trigger('play.owl.autoplay');
      });
    }
    window.carousels.push(owlCarousel);
    owlCarousel.isCurrentAutoplay = options['autoplay'];
    const prevButton = carousel.querySelector('.owl-prev');
    const nextButton = carousel.querySelector('.owl-next');
    const mouseOut = (event) => {
      if (owlCarousel.isCurrentAutoplay)
        owlCarousel.trigger('play.owl.autoplay');
    };
    const mouseIn = (event) => {
      if (owlCarousel.isCurrentAutoplay)
        owlCarousel.trigger('stop.owl.autoplay');
    };
    const buttonClick = (event) => {
      /*if (owlCarousel.isCurrentAutoplay) {
        owlCarousel.trigger('stop.owl.autoplay');
        owlCarousel.isCurrentAutoplay = false;
      }*/
    };

    const navigationFill = () => {

      var pr = owlCarousel.find(".owl-dots .owl-dot span");
      $(pr).css({width: "0"});
      $(pr).stop();
      var progressbar = owlCarousel.find(".owl-dots .owl-dot.active span");
      $(progressbar).animate({width: "100%"}, 8000);
    };

    navigationFill();

    owlCarousel.trigger('refresh.owl.carousel');

    // prevButton.addEventListener('mouseover', mouseIn);
    // prevButton.addEventListener('mouseout', mouseOut);
    prevButton.addEventListener('click', buttonClick);
    // nextButton.addEventListener('mouseover', mouseIn);
    // nextButton.addEventListener('mouseout', mouseOut);
    nextButton.addEventListener('click', buttonClick);
    owlCarousel.on('dragged.owl.carousel', buttonClick);

    owlCarousel.on('changed.owl.carousel', function (event) {
      navigationFill();
    })

    $(window).resize(function () {
    });

  });
})(jQuery, window);
