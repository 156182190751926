(function (window, document, $) {
  $(document).ready(function () {
    $workingMethod = $('.mcl-working-method');
    if ($workingMethod.length > 0) {
      if (window.matchMedia('(min-width: 1024px)').matches) {
        $workingMethod.find('.mcl-icon-wrapper:first').addClass('active');
        $workingMethod.find('.mcl-icon-wrapper').on('click touch', OnClickActiveIconWrapperBoundFunction)
      }

      $workingMethod.find('.mcl-icon-wrapper').on('mouseenter', function () {
        if ($(this).closest('.owl-item').prev().find('.mcl-icon-wrapper').hasClass('active')) {
          $(this).closest('.owl-item').prev().find('.mcl-line').addClass('animate');
        }
      }).on('mouseleave', function () {
        $(this).closest('.owl-item').prev().find('.mcl-line').removeClass('animate');
      });

      function OnClickActiveIconWrapperBoundFunction(e) {
        e.preventDefault();
        e.stopPropagation();
        $workingMethod.find('.mcl-icon-wrapper').removeClass('active');
        $(this).addClass('active');
        var half_height = $(window).height() / 2;
        var targetPosition = $(this).offset().top - half_height;

        $('html, body').animate({
          scrollTop: targetPosition
        }, {
          duration: 200
        });
      }

      function checkWindowWidth() {
        // Get the current window width
        var windowWidth = $(window).width();

        if (windowWidth > 1024) {
          $workingMethod.find('.mcl-icon-wrapper').removeClass('active');
          $workingMethod.find('.mcl-icon-wrapper:first').addClass('active');
          $workingMethod.unbind().on('click touch', '.mcl-icon-wrapper', OnClickActiveIconWrapperBoundFunction);
        } else if (windowWidth < 1024) {
          $workingMethod.off('click touch', '.mcl-icon-wrapper', OnClickActiveIconWrapperBoundFunction);
          $workingMethod.find('.mcl-icon-wrapper').removeClass('active');
        }
      }

      $(window).resize(checkWindowWidth);

      lastScrollTop = 0;
      $(window).scroll(function (event) {
        var st = $(this).scrollTop();
        var activeDiv = $workingMethod.find('.mcl-icon-wrapper.active');

        // Get the current scroll position
        var scrollPosition = $(this).scrollTop();

        // Set the threshold position, in this case, 300px from the bottom
        // Get the viewport height
        var threshold = (1 / 3) * $(window).height();
        if (st > lastScrollTop) {
          // downscroll code

          // Check if the active div is 100px from the top
          if (activeDiv.length && activeDiv.offset().top - scrollPosition <= threshold) {
            if (activeDiv.closest('.owl-item').next().length) {
              activeDiv.closest('.owl-item').next().find('.mcl-icon-wrapper').addClass('active');
              activeDiv.removeClass('active');
              $(this).closest('.owl-item').prev().find('.mcl-line').removeClass('animate');
            }
          }
        } else {
          // upcroll code

          var windowHeight = $(window).height();

          // Check if the active div is 100px from the top
          if (activeDiv.length && scrollPosition + windowHeight - activeDiv.offset().top <= threshold) {
            if (activeDiv.closest('.owl-item').prev().length) {
              activeDiv.closest('.owl-item').prev().find('.mcl-icon-wrapper').addClass('active');
              activeDiv.removeClass('active');
              $(this).closest('.owl-item').next().find('.mcl-line').removeClass('animate');
            }
          }

        }
        lastScrollTop = st;
      });
    }
  })
})(window, document, jQuery);
