(function (window, document, $) {
  function resetDrawers() {
    $('#right-drawer').removeClass('active');
    $('#overlay').fadeOut();
    $('.mcl-header-menu').removeClass('mobile-open');
  }

  $(window).on('resize', () => {
    if ($(window).width() >= 1024) {
      $header = $('.mcl-header-menu');
      $header.find('.menu-trigger').removeClass('button-trigger');
      $('#overlay').fadeOut();
      $header.removeClass('mobile-open');
      $header.find('#right-drawer').removeClass('active');
    }
  });

  $(document).ready(() => {
    $('.menu-trigger').on('click', function (e) {
      if (!$(this).hasClass('button-trigger')) {
        resetDrawers()
        $(this).addClass('button-trigger');
        $('.mcl-header-menu').addClass('mobile-open');
        $('#overlay').fadeIn();
        $('#right-drawer').addClass('active');
      } else {
        $(this).removeClass('button-trigger');
        $('#overlay').fadeOut();
        $('.mcl-header-menu').removeClass('mobile-open');
        $('#right-drawer').removeClass('active');
      }
    });

    $('.mcl-header .mcl-right a.menu-item-has-children, .mcl-header .mcl-secondary-menu').hover(
      function (e) {
        if ($(e.currentTarget).is('.mcl-secondary-menu')) {
          $(e.currentTarget).stop().show();
        } else {
          $(e.currentTarget).siblings('.mcl-secondary-menu').stop().show();
        }
        $('#overlay').show();
      },
      function (e) {
        // se sono nel menu secondo livello non deve fare slideUp
        if ($(e.currentTarget).is('.mcl-secondary-menu')) {
          $(e.currentTarget).stop().hide();
        } else {
          $(e.currentTarget).siblings('.mcl-secondary-menu').stop().hide();
        }
        $('#overlay').hide();
      }
    )

    $('.mcl-accordion-menu-right-drawer').on('click touch', (e) => {
      const $accordion = $(e.target).closest('.mcl-accordion-menu-right-drawer');
      $accordion.siblings('li').toggleClass('hide');
      $accordion.toggleClass('active');
    })
  });
})
(window, document, jQuery);
