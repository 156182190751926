(function ($) {
  $(document).ready(function () {
    $('.mcl-footer .mcl-column-gap .mcl-title').on('click touch', function (e) {
      e.preventDefault();
      if (window.innerWidth < 768) {
        let toSlide = $(this).next().is(':not(:visible)');
        $(this).closest('.mcl-column-gap').find("ul").slideUp();
        $(this).closest('.mcl-column-gap').find(".mcl-title .mcl-icon").removeClass('rotate');
        if (toSlide) {
          $(this).next().slideDown();
          $(this).find(".mcl-icon").addClass('rotate');
        }
      }
    });

  });
})(jQuery);
